import React from "react"
import Layout from "../components/layout"
import ListadoProyectosPageProyectos from "../components/listadoProyectosPageProyectos"
import HeroProyectos from "../components/heroProyectos"
import SEO from "../components/seo"

const ProyectosPage = () => {
  return (
    <Layout>
      <SEO
        title="Proyectos & diseño - Ainoa Molina"
        description="Estos son todos mis proyectos de diseño"
      />
      <HeroProyectos />
      <ListadoProyectosPageProyectos />
    </Layout>
  )
}

export default ProyectosPage
